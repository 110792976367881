<template>
  <v-layout row mt-16 mb-16>
    <v-flex xs12 mt-16>
      <h2 class="text-center blue--text">AVISO DE PRIVACIDAD INTEGRAL PORTAL</h2>
      <v-divider></v-divider>
      <p class="text-center">
        Responsable de cumplimiento de protección de datos personales.
      </p>
    </v-flex>
    <v-layout  justify mt-5>
      <v-flex xs12 mt-10 ml-15 mr-16>
        <p class="text">De conformidad con lo establecido en la ley federal de Protección de Datos Personales en Posesión de los Particulares y su Reglamento le informamos que Proveedor Biotecnológico para Laboratorios S. de R.L de C.V., con domicilio en Av. Pie de la Cuesta No 1500 Fracc. Cuesta Azul CP 76148, es responsable del tratamiento de sus datos personales en términos del presente Aviso de Privacidad Integral de Portal.</p>
        <br>
        <p>El presente Aviso detalla la forma que <b>Proveedor Biotecnológico para Laboratorios S. de R.L de C.V.</b>, utilizan sus datos personales y sobre los derechos que usted tiene.
          <br> Este Aviso provee información importante para las personas que:
        </p>
        <ol>
          <li>
            Visitan, o proporcionan información en el sitio web de Proveedor Biotecnológico para Laboratorios S. de R.L de C.V.; nuestro Aviso de Privacidad también es aplicable para a las personas que utilizan o usan nuestro sitio web, paginas oficiales en redes sociales y las apps que Proveedor Biotecnológico para Laboratorios S. de R.L de C.V. pueda lanzar al mercado o poner a disposición de sus Clientes (todos los anteriores, conjuntamente, nuestras "Propiedades Digitales").
          </li>
          <br>
          <li>Proveedor Biotecnológico para Laboratorios S. de R.L de C.V. recibe datos personales de manera indirecta cuando un Cliente nos proporciona la información de usuarios finales para proveer nuestros servicios y productos requeridos como parte de la relación contractual o laboral que usted tiene con nuestros clientes.
          </li>
        </ol>
        <br>
        <b>¿Cómo obtenemos sus Datos Personales?</b>
        <br>
        <p>Cuando usted nos proporciona directamente sus datos cuando visita nuestro sitio de internet o utiliza nuestros servicios en línea o mediante consulta telefónica o recibimos sus datos de manera indirecta a través del servicio que le préstamos a nuestros clientes, así como a través de otras fuentes que están permitidas por la Ley.</p>
        <br>
        <b>¿Qué tipo de Datos Personales podemos recabar en Proveedor Biotecnológico para Laboratorios S. de R.L de C.V.?</b>
        <br>
        <b>Datos de identificación: </b><p>Información concerniente a una persona física como: nombre, apellidos; edad, firma, código postal, Registro Federal de Contribuyentes (RFC); Clave Única de Registro de Población (CURP); NSS. Género, lugar y fecha de nacimiento; nacionalidad; fotografía; documento que acredite su identidad, documento migratorio, perfil de redes sociales.</p>
        <br>
        <p><b>Datos de Contacto: </b>Información que permite mantener o entrar en contacto con su titular, tal como: domicilio particular y/o fiscal; correo electrónico; teléfono fijo; teléfono celular; datos nombre de usuario de redes sociales para efectos de contacto.</p>
        <p><b>Registros electrónicos: </b>Esta información incluye ciertos datos de usted o de su dispositivo y que son obtenidos cuando usted recibe nuestros correos electrónicos o accede a nuestras Propiedades Digitales, Cookies y web bancos y los registros de voz obtenidos a través de llamadas.</p>
        <p><b>Datos laborales: </b>Información concerniente a una persona física que describe su puesto y fuente de empleo.</p>
        <b>¿Para qué fines recabamos y utilizamos sus datos personales?</b>
        <p>Los datos personales que recabamos de usted, los utilizamos para las siguiente finalidades:</p>
        <b>Finalidades primarias</b>
        <p>Clientes, Proveedores, Afiliados</p>
        <p>Dar cumplimiento a obligaciones legales impuestas por leyes, reglamentos y autoridades competentes.</p>
        <p>Prestación de servicios, de acuerdo con las necesidades particulares de los Clientes/Afiliados.</p>
        <p>Obtención de servicios por parte de los proveedores y/o Afiliados.</p>
        <p>Prospección comercial (portales de venta).</p>
        <p>Informar sobre cambios o nuevos productos o servicios que estén relacionados con los ya contratados o adquiridos.</p>
        <b>Público en general</b>
        <p>Proporcionar contenido que puede ser de su interés.</p>
        <b>De manera General:</b>
        <p>Registro y seguimiento de eventos de promoción y remitir invitaciones para futuros eventos.</p>
        <p>En redes sociales y mensajería instantánea proveer información y orientación para el soporte y venta de nuestros productos que ofrecemos.</p>
        <p>Llevar a cabo procesos administrativos, proveer información y soporte y venta de nuestros productos que ofrecemos.</p>
        <p>Atender y resolver quejas sobre el tratamiento de datos personales.</p>
        <p>Evaluar la calidad del servicio.<br>
          Elaborar estudios de mercado, estadísticas, encuestas y análisis de tendencias del mercado, encuestas de satisfacción sobre los servicios prestados por la empresa.</p>
        <p>Notificar de los servicios, soluciones y productos que se ofrecen en la empresa.</p>
        <b>Finalidades Secundarias</b>
        <p>Envió de promociones, ofertas comerciales, avisos y otros de carácter comercial y de mercadotecnia, mediante publicación en la web, correo electrónico y APPs, mismas que pueden inactivarse en cualquier momento.</p>
        <p>Recibir correos de notificaciones de operaciones en la plataforma.</p>
        <p>Realizar actividades de mercadeo y/o comercialización de nuevos servicios o productos</p>
        <b>Negativa al tratamiento para finalidades secundarias</b>
        <p>Usted puede limitar la recepción de los correos relacionados con estas finalidades, dándose de baja directamente de los correos recibidos.
          En caso de que no desee que sus datos personales sean tratados para estas finalidades secundarias nos puede comunicar lo anterior, desde este momento especificándonos las finalidades secundarias a las que desea manifestar su negativa al tratamiento de sus datos, ya sea a una o para todas las finalidades secundarias al correo <a>atencionaclientes@probiolab.com.mx</a> indicándonos lo siguiente: “No consiento que mis datos personales se utilicen para la(s) siguiente(es) finalidades secundarias ”, especificándonos a cuales de ellas manifiesta su negativa.</p>
        <p>En caso de aplicar, para los datos personales que obtenemos de manera directa, le informamos que usted cuenta con un plazo de 5 días hábiles para manifestar su negativa al tratamiento de sus datos personales ante la entidad que los recabo de manera directa, dado que <b>Proveedor Biotecnológico para Laboratorios S. de R.L de C.V.</b> no los emplea para las finalidades secundarias descritas en el presente Aviso de Privacidad.</p>
        <p>Quedan a salvo, sus Derechos para ejercer la revocación del consentimiento, o su Derecho de Oposición, en caso de que usted no, nos manifieste su negativa para el tratamiento de sus datos personales a las finalidades secundarias.</p>
        <p>Manifestar su negativa al tratamiento de sus datos personales para finalidades secundarias no podrá ser motivo de dejar de brindarle nuestros servicios.</p>
        <b>¿A quién le podemos transferir sus datos personales?</b>
        <p>Nosotros no realizamos transferencias de sus datos personales a terceros.</p>
        <b>¿Cómo acceder o rectificar sus datos personales o cancelar u oponerse a su uso?</b>
        <p>Para poder ejercer sus derechos de acceso, rectificación, cancelación u oposición de sus datos personales deberá requerir el formato “Solicitud para el ejercicio de los Derechos ARCO” mediante el correo electrónico de CORREO que incluya una descripción breve sobre el requerimiento, así como los datos que están involucrados. Posteriormente, tendrá que seguir las instrucciones que le serán enviadas junto con su solicitud.</p>
        <p>Nos comprometemos a atender su solicitud oportunamente, analizando su caso en particular y emitiéndole una respuesta oportuna y justificada.</p>
        <p><b>Proveedor Biotecnológico para Laboratorios S. de R.L de C.V.</b> determinará en cada caso particular la procedencia o no procedencia de su solicitud, haciendo de su conocimiento dicha respuesta.</p>
        <b>¿Cómo contactarnos?</b>
        <p>En todo momento, usted podrá revocar el consentimiento que nos ha otorgado para el tratamiento de sus datos personales a fin de que dejemos de hacer uso de estos.</p>
        <p>Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de sus datos personales de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, debe considerar que, para ciertos fines, la revocación del consentimiento implicara que no podamos seguir brindándole el servicio que le préstamos.</p>
        <p>En caso de revocación de consentimiento deberá requerir el formato “Solicitud para la revocación del consentimiento” mediante el correo electrónico de <a>atencionaclientes@probiolab.com.mx</a> y seguir las instrucciones que serán enviadas junto con su solicitud.</p>
        <p>Nosotros analizaremos su caso y le enviaremos respuesta a su solicitud de acuerdo con nuestro procedimiento establecido.</p>
        <p><b>Proveedor Biotecnológico para Laboratorios S. de R.L de C.V.</b> determinará en cada caso particular la procedencia o no procedencia de su solicitud, haciendo de su conocimiento dicha respuesta</p>
        <b>¿Cómo puede limitar el uso o divulgación de sus datos personales?</b>
        <p>Usted puede limitar el uso o divulgación de los datos personales que nos ha otorgado con fines de publicidad, mercadotecnia y/o prospección comercial, dándose de baja directamente desde los correos recibidos.</p>
        <p>Para dejar de recibir llamadas y/o mensajes en teléfonos fijos, celulares u otros medios, podrá enviar un correo electrónico a <a>atencionaclientes@probiolab.com.mx.</a></p>
        <p>Hacemos de su conocimiento que existen otros mecanismos para limitar el uso y divulgación de sus datos personales, distintos al ejercicio de los derechos ARCO, como son el Registro Público para Evitar Publicidad (REPEP) de la PROFECO, o el Registro Público de Usuarios (REUS)de la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros CONDUSEF para más información y proceder a su registro lo puede realizar desde las páginas web de las dependencias mencionadas. Los registros anteriores no aplican para <b>Proveedor Biotecnológico para Laboratorios S. de R.L de C.V.</b></p>
        <b>Quejas relacionadas con el tratamiento de sus datos personales.</b>
        <p>Si usted considera que su derecho a la protección de sus datos personales ha sido lesionado por alguna conducta u omisión de nuestra parte, tiene alguna duda sobre el presente Aviso de Privacidad Integral de Portal o presume alguna violación a las disposiciones previstas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento y demás ordenamientos aplicables, podrá enviar cualquier queja o duda a nuestro correo <a>atencionaclientes@probiolab.com.mx</a> para su debida atención.</p>
        <p>Para mayor información, sobre la protección de sus Datos Personales y los Derechos que le asisten puede consultar la página oficial del INAI, Organismo garante de la protección de datos personales. www.inai.org.mx.</p>
        <b>Modificaciones al aviso de privacidad</b>
        <p>Nos reservamos el derecho de efectuar en cualquier momento, modificaciones o actualizaciones al presente Aviso de Privacidad Integral del Portal, para la atención de novedades legislativas, políticas internas o nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios o productos. Estas modificaciones estarán disponibles al público a través de nuestra página de Internet avisos; o se las haremos llegar al último correo electrónico que nos haya proporcionado.</p>
      </v-flex>
    </v-layout>
  </v-layout>
</template>
<script>
export default {
  data () {
    return {
      navbar: [
        'Nosotros', 'Representaciones', 'Contacto'
      ]
    }
  }
}
</script>
