<template>
    <v-layout row mt-16 mb-16>
      <v-flex xs12 mt-16>
          <h2 class="text-center blue--text">Contacto</h2>
          <v-divider
          ></v-divider>
          <p class="text-center">
            Para cualquier información o cotización, por favor llene el siguiente formulario, nos pondremos en contacto con usted en breve. Gracias por su atención.
          </p>
        </v-flex>
        <v-flex xs12 mt-9>
          <v-card
            class="mx-auto"
            max-width="60%"
            outlined
            elevation="3"
          >
            <v-list-item>
              <v-list-item-avatar
                tile
                size="300"
                align-center
              >
              <v-img src="https://firebasestorage.googleapis.com/v0/b/probiolab-d2f2c.appspot.com/o/Contacto%2FLogo%20(sin%20fondo).png?alt=media&token=0491d299-3e2d-40e2-b51d-cd06c04d162f">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <validation-observer
                  ref="observer"
                  v-slot="{ invalid }"
                  class="text-center"
                >
                  <form @submit.prevent="submit">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Nombre"
                      rules="required"
                    >
                      <v-text-field
                        v-model="name"
                        :error-messages="errors"
                        label="Nombre"
                        required
                      ></v-text-field>
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Correo"
                      rules="required|email"
                    >
                      <v-text-field
                        v-model="email"
                        :error-messages="errors"
                        label="Correo"
                        required
                      ></v-text-field>
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Mensaje"
                      rules="required"
                    >
                      <v-textarea
                        v-model="message"
                        :error-messages="errors"
                        label="Mensaje"
                        required
                        filled
                        name="input-7-4"
                      ></v-textarea>
                    </validation-provider>
                    <v-btn
                      class="mr-4"
                      outlined
                      color="#4DAC8B"
                      @click="sendEmail"
                      :disabled="invalid"
                    >
                      Enviar
                    </v-btn>
                  </form>
                </validation-observer>
                <a class="text-right grey--text" href="https://probiolab.com.mx/#/Aviso-de-privacidad" @click = getPos(busstop)>Aviso de Privacidad</a>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-flex>
        <v-snackbar v-model="snackbar" timeout="4000"  elevation="5" button color="success" class="mx-auto d-flex">
          {{ text }}
          <v-btn
          color="red"
          text
          @click="snackbar = false, clear"
          >
            Close
          </v-btn>
        </v-snackbar>
     </v-layout>
</template>
<script src='https://smtpjs.com/v3/smtp.js'></script>
<script>
import { required, digits, email, max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import LoadScript from 'vue-plugin-load-script'

setInteractionMode('eager')

extend('digits', {
  ...digits,
  message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
  ...required,
  message: '{_field_} no puede estar vacío',
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters',
})

extend('email', {
  ...email,
  message: 'Correo electrónico no válido. Por favor, usa el formato: tucorreo@ejemplo.com',
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data () {
    return {
      navbar: [
        'Nosotros', 'Representaciones', 'Contacto'
      ],
      multiLine: true,
      snackbar: false,
      text: 'Correo enviado, nos pondremos en contacto a la brevedad.',
      name: '',
      email: '',
      message: '',
      phoneNumber: ''
    }
  },
  computed: {
  },

  methods: {
    getPos(busstop) {
      const left = this.$refs.busstop.getBoundingClientRect().left
      const top = this.$refs.busstop.getBoundingClientRect().top
    },
    clear () {
      this.name = ''
      this.email = ''
      this.message = ''
      this.$refs.observer.reset()
    },
    sendEmail () {
      this.$refs.observer.validate()
      this.$loadScript('https://smtpjs.com/v3/smtp.js')
      .then(() => {
        window.Email.send({
          SecureToken: '45eed7a3-38bd-474d-bc6c-269fb53919c0',
          To: 'compras@probiolab.com.mx',
          From: 'compras@probiolab.com.mx',
          Subject: 'Info probiolab.com.mx',
          Body: '<div> Nombre: ' + this.name + '</div>' + '<div>Correo: ' +  this.email  + '</div>' + '<div> </div>' + '</div>' + this.message + '</div>'
        }).then(
          this.clear(),
          this.snackbar=true
        )
      })
      .catch(() => {
      // Failed to fetch script
      });
    },
    submit () {
      this.$refs.observer.validate()
    }
  }
}
</script>
