import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#3f51b5',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c',
        logo1: '#abcf6a',
        logo2: '#0094cf',
        appbar: '#1E3E62',
        icon: '#4DAC8B',
        white: '#ffffff'
      }
    }
  }
})
