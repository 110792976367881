import Vue from 'vue'
import VueRouter from 'vue-router'
import Nosotros from '@/components/Nosotros'
import Representaciones from '@/components/Representaciones/Representaciones'
import Contacto from '@/components/Contacto/Contacto'
import AvisoPrivacidad from '@/components/AvisoPrivacidad/AvisoPrivacidad'
import LoadScript from 'vue-plugin-load-script'

Vue.use(VueRouter)
Vue.use(LoadScript)

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: Nosotros
  },
  {
    path: '/Nosotros',
    name: 'Nosotros',
    component: Nosotros
  },
  {
    path: '/Representaciones',
    name: 'Representaciones',
    component: Representaciones
  },
  {
    path: '/Contacto',
    name: 'Contacto',
    component: Contacto
  },
  {
    path: '/Aviso-de-privacidad',
    name: 'AvisoPrivacidad',
    component: AvisoPrivacidad
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
