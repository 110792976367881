<template>
   <v-content>
       <v-layout row>
            <v-flex xs12>
               <v-carousel :show-arrows="false" height="400" cycle>
                <v-carousel-item
                    v-for="(item,i) in carrusel"
                    :key="i"
                    :src="item.src"
                ></v-carousel-item>
                </v-carousel>
           </v-flex>
       </v-layout>
       <v-layout justify-center row mt-5>
         <v-flex xs8 mt-16>
           <h2 class="text-center blue--text">Nuestra compañía</h2>
           <v-divider
            inset
            ></v-divider>
            <p class="text-center">Somos una empresa dedicada a proveer amplia variedad de productos para laboratorio para satisfacer
                                  necesidades industriales y académicas. Probiolab es una empresa líder con alto conocimiento en los
                                  requerimientos del mercado contando con amplia experiencia, reconocimiento, confianza y la satisfacción
                                  de nuestros clientes.
            </p>
         </v-flex>
       </v-layout>
       <v-layout v-for="(item, i) in items"
          :key="i"
          row mt-16 mb-16>
         <v-flex xs12>
             <v-row no-gutters>
                <v-card
                class="mx-auto"
                max-width="900"
                >
                <v-list-item>
                  <v-list-item-avatar
                    tile
                    size="200"
                    color="grey"
                  >
                  <v-img :src="item.src"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="headline mb-1 pink--text text-center" v-text="item.title"></v-list-item-title>
                    <v-list-item-title class="title mb-2"></v-list-item-title>
                    <div v-text="item.text">
                    </div>
                  </v-list-item-content>
                  </v-list-item>
                </v-card>
            </v-row>
         </v-flex>
       </v-layout>
        <v-divider
        ></v-divider>
       <v-layout justify-center row>
         <v-flex xs8 mt-8 mb-5>
           <h3 class="text-center">Valores</h3>
           <v-divider
              inset
            >
            </v-divider>
            <v-layout justify-center row>
              <v-flex v-for="(item, i) in valores"
                :key="i"
                mt-10 mb-5
                justify-center>
                <v-card
                elevation="0"
                max-width="130"
                >
                <v-img
                  class="pa-md-8 mx-lg-auto"
                  :src="item.src"
                  max-width="60"
                ></v-img>
                <v-card-text class="text-center">
                  <h3 v-text="item.title"></h3>
                </v-card-text>
              </v-card>
              </v-flex>
            </v-layout>
         </v-flex>
       </v-layout>
    </v-content>
</template>
<script>
export default {
  data () {
    return {
      carrusel: [
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/probiolab-d2f2c.appspot.com/o/Nosotros%2FCarrusel%2FGrupo%201.png?alt=media&token=f76c8ad0-b6fd-43c0-b73a-c3822c5f7eaf'
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/probiolab-d2f2c.appspot.com/o/Nosotros%2FCarrusel%2Fpexels-artem-podrez-5726794.jpg?alt=media&token=807244c0-d7ae-4feb-afc1-de5ad5798d1a'
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/probiolab-d2f2c.appspot.com/o/Nosotros%2FCarrusel%2Fpexels-polina-tankilevitch-3735718.jpg?alt=media&token=2361618d-836f-4350-a364-0c8aba4cd28c'
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/probiolab-d2f2c.appspot.com/o/Nosotros%2FCarrusel%2Fpexels-thisisengineering-3912364.jpg?alt=media&token=ecfddc21-47bc-4e97-8a72-92d881d29185 '
        }
      ],
      items: [
        {
          color: '#1F7087',
          src: 'https://images.pexels.com/photos/2280571/pexels-photo-2280571.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
          title: 'Objetivo',
          text: 'Ofrecer a nuestros clientes una atención de inmediata en productos y equipos para laboratorio en condiciones óptimas de calidad.'
        },
        {
          color: '#1F7087',
          src: 'https://images.pexels.com/photos/1366942/pexels-photo-1366942.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
          title: 'Misión',
          text: 'Somos una empresa comercializadora comprometida a ofrecer los artículos para los laboratorios excediendo constantemente las expectativas de nuestros clientes, proveedores, y sociedad donde la calidad de atención, mejora continuamente para obtener siempre la preferecia de nuestros clientes.'
        },
        {
          color: '#952175',
          src: 'https://images.pexels.com/photos/256262/pexels-photo-256262.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
          title: 'Visión',
          text: 'Ser una empresa líder en el mercado a través de ofertar una amplia variedad de artículos reconocidos por su buena calidad y precio, manteniendo una filosofía permanente de innovación, servicio y calidad, sustentados en valores, tecnología y capital humano.'
        }
      ],
      valores: [
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/probiolab-d2f2c.appspot.com/o/Nosotros%2Fapreton-de-manos.png?alt=media&token=801dd458-96e8-44e6-a337-ab6358f432eb',
          title: 'Cooperación'
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/probiolab-d2f2c.appspot.com/o/Nosotros%2Fgestion-de-proyectos.png?alt=media&token=b6fd1221-0e94-4a7d-a691-0ae0f1f937aa',
          title: 'Innovación'
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/probiolab-d2f2c.appspot.com/o/Nosotros%2Fproteger.png?alt=media&token=0322251e-ffd2-46dd-8c09-e948fa8c0e3b',
          title: 'Seguridad'
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/probiolab-d2f2c.appspot.com/o/Nosotros%2Fmotivacion.png?alt=media&token=19fe77a3-b9e5-4075-8f95-d5dec67411e5',
          title: 'Motivación'
        }
      ],
      navbar: [
        'Nosotros', 'Representaciones', 'Contacto'
      ]
    }
  }
}
</script>
