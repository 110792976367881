<template>
    <v-layout row mt-16>
        <v-flex xs12 mt-16>
          <h2 class="text-center blue--text">Líneas representadas</h2>
          <v-divider
          ></v-divider>
          <p class="text-center">
            Contamos con un amplio portafolio de productos y servicios que nos posicionan como un socio de negocio con la capacidad de
            ofrecer soluciones integrales.
          </p>
        </v-flex>
        <v-item-group>
          <v-layout justify-center row mb-16>
            <v-flex mt-16 ml-6 mr-6 v-for="(item, i) in representaciones"
                  :key="i" >
              <v-item v-slot="{ active, toggle }">
                <v-card
                  class="mx-auto d-flex flex-column"
                  max-width="344"
                  height="100%"
                >
                  <v-img
                  contain
                  :src="item.img"
                  height="200px"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-1"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-card-title v-text="item.nombre">
                </v-card-title>
                <v-card-subtitle v-text="item.texto">
                </v-card-subtitle>
                  <v-card-actions>
                    <v-btn
                      text
                      color="teal accent-4"
                      @click="toggle"
                    >
                      Ver productos
                    </v-btn>
                  </v-card-actions>

                  <v-expand-transition>
                    <v-card
                      v-if="active"
                      class="transition-fast-in-fast-out"
                      style="bottom: 0;
                            opacity: 1 !important;
                            position: absolute;
                            width: 100%;
                            height: 60%;"
                    >
                      <v-card-text class="pb-0">
                        <p class="headline text--primary">Productos</p>
                        <lo v-for="(prod, i) in item.productos"
                        :key="i">
                          <li v-text="prod"></li>
                        </lo>
                        <li>mas..</li>
                      </v-card-text>
                      <v-card-actions class="pt-0 mx-auto d-flex flex-column">
                        <v-btn
                          text
                          color="teal accent-4"
                          @click="toggle"
                        >
                          Close
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-expand-transition>
                </v-card>
              </v-item>
          </v-flex>
          </v-layout>
        </v-item-group>
     </v-layout>
</template>
<script>
export default {
  data () {
    return {
      active: false,
      representaciones: [
        {
          img: 'https://img.hannainst.es/img/hannainst-logo-1524057508.jpg',
          nombre: 'HANNA instruments',
          productos: ['Medidores de pH', 'Fotómetros', 'Colorimetros', 'turbidimetro', 'Refractómetros', 'Medidores Multiparametricos'],
          texto: 'Hanna Instruments es líder mundial en el desarrollo y fabricación de instrumentos de medición electroquímicos, fotométricos y electrodos. Durante más de 37 años Hanna se ha enorgullecido de ser líder mundial en innovación de equipos de laboratorio.',
          link: 'https://www.hannainst.es/'
        },
        {
          img: 'https://firebasestorage.googleapis.com/v0/b/probiolab-d2f2c.appspot.com/o/Marcas%2FKimble%2FKIMBLE_2_logo.png?alt=media&token=95da49fc-6c67-4976-bb86-4cc13fadbf50',
          nombre: 'KIMBLE',
          productos: ['Vidriería en general para laboratorio', 'Vasos de precipitado', 'Probetas', 'Pipetas de vidrio'],
          texto: 'Con más de 11.000 productos, ofrece la gama más completa de productos de vidrio de laboratorio para aplicaciones científicas, fabricados principalmente según las normas ASTM.',
          link: 'https://www.hannainst.es/'
        },
        {
          // img: 'https://spinreact.com.mx/public/newsite/wp-content/uploads/2018/03/LOGO-Spinreact.jpg',
          img: 'https://firebasestorage.googleapis.com/v0/b/probiolab-d2f2c.appspot.com/o/Marcas%2FSpinreact%2FSpinVect-logo.png?alt=media&token=b130ba93-d1d2-4c41-bde9-5bc6b5a01eaf',
          nombre: 'SPINREACT',
          productos: ['Reactivos para diagnostico Invitro', 'Bioquímica Clínica', 'Serología', 'Inmunoquímica', 'Hematología y Hesmostasia', 'Prueba Rápidas', 'Instrumentación Micro pipetas'],
          texto: 'Nuestra vocación nos hace trabajar cada día para poder ofrecer soluciones adaptadas a las necesidades de nuestros distribuidores y de todos los laboratorios del mundo.',
          link: 'https://www.hannainst.es/'
        },
        {
          img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDCjlWGEuLwojJJMTvhMyxRlAKocnJ5aiNBILFR-qNrXgBcuUzfDcxhMhMetDyvMdiXA&usqp=CAU',
          nombre: 'SPL',
          productos: ['Consumibles de biología Molecular', 'Consumibles de Cultivo Celular', 'Placas de 96 pozos, 48, 24, y 6 pozos', 'Tubos cónicos de 15 y 50 ml'],
          texto: 'SPL está comprometida a producir equipo de laboratorio plástico de la mas alta calidad para la industria de biotecnología y ha desarrollado una amplia gama de productos usando la más alta precisión en modelado de inyección, tecnología de polímeros y química de superficie.',
          link: 'http://www.spllifesciences.com/en/'
        },
        /*
        {
          img: 'https://whirl-pak.com/images/thumbs/0000461.png',
          nombre: 'WHIRL-PAK',
          productos: ['Bolsas de Muestreo '],
          texto: 'Los productos de muestreo Whirl-Pak son ideales para todas las industrias, incluidos los mercados de alimentos y bebidas, lácteos, agua, médicos, veterinarios, ambientales, de suelos e industriales, así como para el muestreo de ciencia forense, genética, biomédica y farmacéutica.',
          link: 'https://www.whirl-pak.com/'
        },
        */
        {
          img: 'https://www.mt.com/etc/designs/mt/widgets/shared/css/images/static/mt-logo.png',
          nombre: 'METTLER TOLEDO',
          productos: ['Micropipetas Automáticas y Manuales', 'Puntas y tubos para Micro pipetas', 'Balanzas Analíticas', 'Balanzas Micro analíticas'],
          texto: 'Es un fabricante líder mundial de instrumentos de precisión y servicios para uso en el laboratorio y en los procesos de fabricación.',
          link: 'https://www.mt.com/mx/es/home.html'
        },
        {
          img: 'https://tukuz.com/wp-content/uploads/2020/02/bio-rad-laboratories-logo-vector.png',
          nombre: 'Bio-Rad',
          productos: [],
          texto: 'Es un líder mundial en el desarrollo, fabricación y comercialización de una amplia gama de productos innovadores para los mercados de investigación de ciencias biológicas y diagnóstico clínico.',
          link: 'https://www.bio-rad.com/'
        },
        {
          img: 'https://codiagnostics.com/wp-content/uploads/2018/10/CD-header-logo-300.png',
          nombre: 'CO-DIAGNOSTICS INC',
          productos: ['Kit de PCR para Tiempo Real para detección de SARS COV-2', 'Kit de PCR para Tiempo Real para detección de Logix Smart (Zika, Dengue y Chikunguña)'],
          texto: 'Ofrece una gama de productos y servicios que se adaptan a las necesidades de los laboratorios de diagnóstico, las instituciones de investigación y las empresas de todo el mundo que dependen de la tecnología de PCR.',
          link: 'https://codiagnostics.com/'
        }
        /*
        {
          img: 'https://science-med.com/image/logo.png',
          nombre: 'Science MED',
          productos: ['Micropipetas Manuales y automáticas', 'Dispensadores', 'Termocicladores', 'Termomixer', 'Rotavapores', 'Parrillas con Agitación'],
          texto: 'Somos un fabricante profesional de instrumentos de laboratorio de alta calidad para la investigación. Nuestra empresa se dedica al desarrollo y fabricación de instrumentos de laboratorio en general, ofreciendo a los investigadores un rendimiento inigualable y un valor probado.',
          link: 'https://science-med.com/index.php'
        },
        {
          img: 'https://www.expodiclab.com/images/generales/logosocios/KaralExpo.png',
          nombre: 'Karal',
          productos: ['Productos elaborados de acuerdo a especificaciones American Chemical Society ( ACS) organización internacional que establece los parámetros en calidad.'],
          texto: 'Nuestra empresa es 100% mexicana y fue fundada en 1999 con la finalidad de proveer reactivos analíticos y productos químicos de calidad, para satisfacer las necesidades crecientes de este mercado.',
          link: 'http://www.karal.com.mx/'
        },
        {
          img: 'https://www.diclab.com.mx/media/zoo/images/meyer_d33c4beb441984bdb4cda96a7a774006.png',
          nombre: 'MEYER',
          productos: [],
          texto: 'Es una empresa 100 % mexicana con más de 10 años de experiencia en la distribución de reactivos, analíticos, productos técnicos, industriales y materias primas de alta pureza, que cumplen con el estándar y las normas de calidad, por lo que su gama de productos es de alta pureza y confiabilidad.',
          link: 'http://www.reactivosmeyer.com.mx/'
        },
        {
          img: 'https://image.jimcdn.com/app/cms/image/transf/dimension=418x10000:format=jpg/path/sc151090f8fbfc9a9/image/i0e141b9630f460ac/version/1609397602/dlab-scientific-m%C3%A9xico.jpg',
          nombre: 'DLAB',
          productos: ['Pipetas', 'Dispensadores de líquidos'],
          texto: 'DLAB Scientific es un proveedor establecido de equipos de laboratorio de alto valor dedicado a desarrollar y fabricar herramientas de aplicación innovadoras y fiables con un rendimiento inigualable para mejorar el éxito y el valor de la operación del cliente.',
          link: 'https://www.bioneer.com/'
        },
        {
          img: 'https://www.bioneer.com/img/logo.png',
          nombre: 'BIONEER',
          productos: ['Equipos para Manejos de líquidos', 'Homogenizador de Tejidos', 'Kits de Purificación de Ácidos Nucleicos', 'Multiplex para PCR', 'Kits Predispensados para PCR, RT-PCR Y qPCR'],
          texto: 'Bioneer proporciona los mejores productos a precio de valor para el mercado mundial de la salud mediante la aplicación de tecnologías de vanguardia que brindan tratamientos que salvan vidas y diagnósticos al mayor número de personas.',
          link: 'https://www.bioneer.com/'
        }
        */
      ],
      navbar: [
        'Nosotros', 'Representaciones', 'Contacto'
      ]
    }
  }
}
</script>
