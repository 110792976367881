var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"row":"","mt-16":"","mb-16":""}},[_c('v-flex',{attrs:{"xs12":"","mt-16":""}},[_c('h2',{staticClass:"text-center blue--text"},[_vm._v("Contacto")]),_c('v-divider'),_c('p',{staticClass:"text-center"},[_vm._v(" Para cualquier información o cotización, por favor llene el siguiente formulario, nos pondremos en contacto con usted en breve. Gracias por su atención. ")])],1),_c('v-flex',{attrs:{"xs12":"","mt-9":""}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"60%","outlined":"","elevation":"3"}},[_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"tile":"","size":"300","align-center":""}},[_c('v-img',{attrs:{"src":"https://firebasestorage.googleapis.com/v0/b/probiolab-d2f2c.appspot.com/o/Contacto%2FLogo%20(sin%20fondo).png?alt=media&token=0491d299-3e2d-40e2-b51d-cd06c04d162f"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}])})],1),_c('v-list-item-content',[_c('validation-observer',{ref:"observer",staticClass:"text-center",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Nombre","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Correo","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Correo","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Mensaje","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"label":"Mensaje","required":"","filled":"","name":"input-7-4"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})]}}],null,true)}),_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"#4DAC8B","disabled":invalid},on:{"click":_vm.sendEmail}},[_vm._v(" Enviar ")])],1)]}}])}),_c('a',{staticClass:"text-right grey--text",attrs:{"href":"https://probiolab.com.mx/#/Aviso-de-privacidad"},on:{"click":function($event){return _vm.getPos(_vm.busstop)}}},[_vm._v("Aviso de Privacidad")])],1)],1)],1)],1),_c('v-snackbar',{staticClass:"mx-auto d-flex",attrs:{"timeout":"4000","elevation":"5","button":"","color":"success"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" "),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.snackbar = false, _vm.clear}}},[_vm._v(" Close ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }