<template>
  <v-app>
    <v-app-bar
      flat
      app
      color="#1E3E62"
      >
      <h1 class="indigo--text logo1--text">Pro</h1>
      <h1 class="indigo--text logo2--text">BioLab</h1>
      <v-div class="hidden-xs-only ma-2">
        <div class="d-inline-block text-subtitle-1 white--text">
          <v-icon color="#4DAC8B" left>
          mdi-phone
          </v-icon>
          (442) 227 67 90
        </div>
        <div  class="d-inline-block text-subtitle-1 white--text">
          <v-icon color="#4DAC8B" right>
          mdi-power-on
          </v-icon>
        </div>
        <div  class="d-inline-block text-subtitle-1 white--text">
          <v-icon color="#4DAC8B" left>
          mdi-email
          </v-icon>
          compras@probiolab.com.mx
        </div>
      </v-div>
      <v-spacer></v-spacer>
      <v-app-bar-items class="hidden-sm-only hidden-xs-only">
        <v-btn icon color="#4DAC8B" @click="facebook()" target="_blank">
          <v-icon>
          mdi-facebook
          </v-icon>
        </v-btn>
        <v-btn icon color="#4DAC8B" @click="whatsapp()">
          <v-icon>
          mdi-whatsapp
          </v-icon>
        </v-btn>
        <!--<v-btn icon color="#4DAC8B">
          <v-icon>
          mdi-twitter
          </v-icon>
        </v-btn>-->
      </v-app-bar-items>
      <template v-slot:extension>
        <v-layout row>
           <v-flex xs12>
             <v-card tile elevation="2">
                <v-tabs
                  grow
                >
                  <v-tab
                    v-for="item in navbar"
                    :key="item"
                    :to="'/'+ item" tag="span" style="cursor: pointer"
                  >
                    {{ item }}
                  </v-tab>
                </v-tabs>
              </v-card>
          </v-flex>
        </v-layout>
      </template>
    </v-app-bar>
    <main>
      <router-view></router-view>
    </main>
    <v-footer
    dark
    padless
    inset app
    >
      <v-card
      class="flex "
      flat
      >
        <!--
        <v-card-title class="white--text icon rounded-0">
          <v-btn
          v-for="link in links"
          :key="link"
          color="white"
          text
          class="flex"
          :to="'/'+ link" tag="span" style="cursor: pointer"
          >
         {{ link }}
          </v-btn>
          <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-4"
          icon
          >
          <v-icon size="24px">
              {{ icon }}
          </v-icon>
          </v-btn>
        </v-card-title>
        -->
        <v-card-text class="py-2 white--text text-center appbar justify">
          <v-icon>
          mdi-copyright
          </v-icon>
          Proveedor Biotecnológico para Laboratorios S. de R.L  de C.V
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>
<script src='https://smtpjs.com/v3/smtp.js'></script>
<script>
// import { ValidationProvider } from 'vee-validate';
export default {
  name: 'App',
  components: {
    // ValidationProvider
  },

  methods: {
    facebook () {
      window.open('https://www.facebook.com/ProbioLab-108048524697515', '_blank')
    },
    whatsapp () {
      window.open('https://api.whatsapp.com/send?phone=524422068114', '_blank')
    }
  },

  data: () => ({
    icons: [
      'mdi-facebook',
      'mdi-whatsapp'
      // 'mdi-twitter'
    ],
    links: [
      'Nosotros',
      'Representaciones',
      'Contacto',
      'Aviso-de-privacidad'
    ],
    navbar: [
      'Nosotros', 'Representaciones', 'Contacto'
    ]
  })
}
</script>
